import React from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { useTranslation } from 'react-i18next'

export const SetSecretQuestionSuccess = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <p className="text-xl px-12 text-center pb-64">
        {t('秘密の質問の設定ありがとうございました')}
      </p>
    </Layout>
  )
}
